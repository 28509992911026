import './globals';
import './viewer.scss';
import { VevContent } from 'vev';
import React from 'react';
import { hydrate } from 'react-dom';
import { Router, importAllWidgets, View, App, store, hydratePreRenderedModels } from './core';
import polyfills from './core/polyfills';
import { disabledPreload } from './core/page-resolver';

export * from './core';
export function render(content: VevContent) {
  store('content', content);

  const root = document.getElementById('__vev');
  Promise.all([importAllWidgets(), polyfills()]).then(() => {
    const embed = root.getAttribute('embed');
    Router.init(!embed);
    // If embed enabled them disable preloading of pages and set page to embed page key or index page if not found
    if (embed) {
      const page = Router.page(embed);
      Router.set(page ? page.key : Router.indexKey);
      disabledPreload();
    }
    View.enable(content.scaling);
    hydratePreRenderedModels(root);
    hydrate(<App pageKey={Router.currentPage} />, root);
  });
}
