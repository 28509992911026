import { IPage, PageResolver, VevContent, IContent } from 'vev';
import { unmountContent, doc, removeClass, addClass, getById, root, isBrowser } from '../utils';
import Timeline from '../model/timeline';
// Managers
import View from './view';
import { resolvePage } from '../core/page-resolver';
import AppState, { store } from '../core/state';
import { raf } from '../utils/animation';

const DOUBLE_SLASH = /\/{2,}/g;
const CENTER_VALUES = { x: 0, y: 0, opacity: 1, scale: 1 };

const location: Location = <Location>doc.location;

const join = (...path: string[]) => (path.join('/') + '/').replace(DOUBLE_SLASH, '/');
function cleanPath(path: string) {
  return (
    path
      // Remove query or hash
      .replace(/(\?|#).*$/, '')
      // Remove double slash
      .replace(DOUBLE_SLASH, '/')
      // Remove trailing slash
      .replace(/\/$/, '')
      .replace(/index.html$/i, '')
  );
}

function findRootParent(modelKey: string): IContent | undefined {
  const parent = AppState.content.models.find(
    m => m.children && m.children.indexOf(modelKey) !== -1
  );
  return (parent && findRootParent(parent.key)) || parent;
}

let prevScroll: { [pageKey: string]: number } = {};

/**
 * @event route: (routeManager : RouteManager)
 */
class RouteManager {
  pages: IPage[] = [];
  baseDir: string = '/';
  prevPath?: string;
  prevPage?: string;
  indexKey?: string;
  private enabled: boolean = false;
  private transition: Timeline = new Timeline(this.tComplete.bind(this));
  /** Current tag is the currently active page tag */
  currentTag?: HTMLElement;
  /** Used when transition between pages */
  private nextTag?: HTMLElement;
  /** Current page key */
  public currentPage?: string;
  /**
   * Page resolver resolves the dom element for the page
   * Default resolver just fetches path (index.html file for page) and extracts page html as dom element
   * Editor uses a custom resolver
   */
  public resolver: PageResolver = resolvePage;
  public unmountTag: (tag: Element) => void = unmountContent;
  // public renderFixed: (
  //   newFixed: HTMLCollectionOf<Element> | Element[]
  // ) => void = replaceFixedContent;

  get path(): string {
    return cleanPath(location.pathname + '/');
  }

  init(enable?: boolean) {
    store('content', this.update);
    this.update(AppState.content);

    if (isBrowser) {
      this.prevPath = this.path;
      if (enable && !this.enabled) {
        this.enabled = true;
        this.currentPage = this.pageKeyByPath(this.path);
        store('route', { pageKey: this.currentPage, path: this.path });
        window.addEventListener('popstate', this.handleChange);
      }
    }
  }

  update = (content: VevContent) => {
    this.baseDir = cleanPath(`/${content.dir || ''}/`);
    this.pages = content.pages || [];

    for (let page of this.pages) {
      page.path = cleanPath(page.path);
      if (page.index) this.indexKey = page.key;
    }
  };

  /**
   * Go to give path, if a page is found, with given tween
   *  */
  async go(path: string, tween?: any) {
    const [pagePath, widgetKey] = path.split('#');
    let pageKey: string;
    if (widgetKey) {
      const page = findRootParent(widgetKey);
      if (page) pageKey = page.key;
    }
    if (!pageKey) pageKey = this.pageKeyByPath(pagePath) || this.currentPage;

    console.log('Go to page ', path, pageKey, widgetKey, this.currentPage);
    // if not same as current page then change to page
    // else if widgetKey then scroll to widget
    if (pageKey !== this.currentPage) {
      const t = this.transition;
      t.reset();

      // If we still have next tag, then transition is not complete.
      // So we need too trigger the complete to clean up and make sure it's the right page tag we're transitioning to
      if (this.nextTag) this.tComplete();

      if (!this.currentPage) throw new Error('Current page is missing');

      this.currentTag = <HTMLElement>getById(this.currentPage);
      const [page, fixed] = await this.resolver(pageKey, <string>this.pagePathByKey(pageKey));

      this.nextTag = page;
      this.set(pageKey);
      // Set static height to the body to prevent any jumping
      // also required when we pin the pages for animation
      root.style.height = this.currentTag.clientHeight + 'px';

      // (<HTMLElement>this.currentTag.parentElement).insert(this.nextTag, this.currentTag)
      this.currentTag.insertAdjacentElement(
        tween && tween.inFront ? 'afterend' : 'beforebegin',
        this.nextTag
      );

      // this.renderFixed(fixed);

      // If no tween we can just run the complete transition trigger
      // Else setup for transition an start the transition
      if (!tween) {
        this.tComplete(false);
        raf(() => {
          View.setScrollTop(widgetKey ? View.getElCenteringPos(widgetKey, this.nextTag) : 0);
        });
      } else {
        addClass(this.nextTag, 'pin');
        addClass(this.currentTag, 'pin');

        this.currentTag.scrollTop = View.scrollTop;
        // The pin class sets hight of page to 100vh, so need to set the scroll top inside the page container
        // Next tags scroll top is set to desired widget pos or 0
        this.nextTag.scrollTop = widgetKey ? View.getElCenteringPos(widgetKey, this.nextTag) : 0;

        // Set axis scaling to be the viewport size on x an y
        t.scaling({ x: View.width, y: View.height });
        t.fromValues(this.currentTag, tween.tweenOut, CENTER_VALUES);
        t.toValues(this.nextTag, tween.tweenIn, CENTER_VALUES);
        t.play();
      }
    } else if (widgetKey) View.scrollTo(widgetKey);
  }

  /**
   *  Get page path by key
   */
  pagePathByKey(pageKey: string): string | void {
    const page = this.page(pageKey);
    if (page) return join(this.baseDir, page.path);
  }

  /**
   * Get page key by path
   */
  pageKeyByPath(path: string = this.prevPath || ''): string {
    path = cleanPath(path);
    if (!path || path === '/' || path === this.baseDir) return <string>this.indexKey;

    for (let page of this.pages) {
      if (path === page.key) return path;
      // Skip index page because it's the fallback if no match
      if (page.index) continue;
      // Finding start index in path for the page path (may be none (-1)), also checking if path is sub string of path
      let startIndex = path.indexOf(page.path);

      // If page path is substring
      // then we check if they're a perfect match  extracting substring of the path
      if (startIndex !== -1 && path.substring(startIndex) === page.path) {
        return page.key;
      }
    }
    return <string>this.indexKey;
  }

  page(pageKey: string): IPage | void {
    for (const page of this.pages) if (page.key === pageKey) return page;
  }

  set(pageKey: string): void {
    // console.log('SET PAGE', pageKey);
    // if (!page) throw new Error('Could not find page: ' + pageKey);
    if (this.currentPage !== pageKey) {
      this.prevPath = this.path;
      this.prevPage = this.currentPage;
      this.currentPage = pageKey;
      store('route', { pageKey: this.currentPage, path: this.path });

      const page = this.page(pageKey);
      if (this.enabled && page) {
        history.pushState(
          { pageKey, prevPageKey: this.prevPage, title: page.title },
          page.title,
          join(this.baseDir, page.path) + location.search + location.hash
        );
      }
    }
  }

  // /** Checks if the path is same as the last path */
  // private isLastPath(path: string): boolean {
  //   return path === this.prevPath;
  // }

  private handleChange = async (e: PopStateEvent): Promise<void> => {
    const path = this.path;
    this.enabled = false;
    await this.go(path);
    this.enabled = true;
  };

  /** On page transition start */
  /** On page transition complete */
  private tComplete(blockScroll?: boolean) {
    // Removing the previous page
    if (this.currentTag) this.unmountTag(this.currentTag);
    let scrollTop = 0;
    if (this.nextTag) {
      // Setting the document scroll top to the stored scroll top in the next tag
      scrollTop = this.nextTag.scrollTop;
      // Removing the added style to body
      doc.body.style.height = null;
      removeClass(this.nextTag, 'pin', 'front');
    }

    if (blockScroll !== true) View.setScrollTop(scrollTop);
    this.currentTag = this.nextTag;

    delete this.nextTag;
  }
}

export default new RouteManager();
